import type { AnalyticsBrowser } from '@segment/analytics-next'

export function useSegment() {
  const $segment = useNuxtApp().$segment

  // See: https://segment.com/docs/connections/spec/identify/
  function identify(...args: Parameters<AnalyticsBrowser['identify']>) {
    $segment.identify(...args)
  }

  // See: https://segment.com/docs/connections/spec/page/
  function page(...args: Parameters<AnalyticsBrowser['page']>) {
    $segment.page(...args)
  }

  // See: https://segment.com/docs/connections/spec/track/
  function track(...args: Parameters<AnalyticsBrowser['track']>) {
    $segment.track(...args)
  }

  // See: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-log-out
  function reset(...args: Parameters<AnalyticsBrowser['reset']>) {
    return $segment.reset(...args)
  }

  return {
    $segment,
    identify,
    page,
    track,
    reset,
  }
}
